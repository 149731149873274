import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useClientsList() {
  // Use toast
  const toast = useToast()
  const refClientListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'description', sortable: true},
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalClients = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const statusFilter = ref(true)
  const recordDeleted = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refClientListTable.value ? refClientListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalClients.value,
    }
  })

  const refetchData = () => {
    refClientListTable.value.refresh()
  }

  const whitelabelSelect = computed(() => {
    return store.state.whitelabelCurrencyNabvar.whitelabel._id
  })
  const currencySelect = computed(() => {
    return store.state.whitelabelCurrencyNabvar.currency
  })

  watch([currentPage, perPage, searchQuery,  statusFilter, recordDeleted, whitelabelSelect, currencySelect], () => {
    refetchData()
  })

  const fetchClients = (ctx, callback) => {
    store
      .dispatch('app-client/fetchClients', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        whitelabel: whitelabelSelect.value,
        currency: currencySelect.value
      })
      .then(response => {
        const { clients, total } = response.data
        callback(clients)
        totalClients.value = total
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching clients list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveClientStatusVariant = status => {
    if (status === false) return 'warning'
    if (status === true) return 'success'
    return 'primary'
  }

  return {
    fetchClients,
    tableColumns,
    perPage,
    currentPage,
    totalClients,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refClientListTable,
    resolveClientStatusVariant,
    refetchData,
    recordDeleted,
    // Extra Filters
    statusFilter,
  }
}
