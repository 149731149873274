import { render, staticRenderFns } from "./ClientListAddNew.vue?vue&type=template&id=365ea51d"
import script from "./ClientListAddNew.vue?vue&type=script&lang=js"
export * from "./ClientListAddNew.vue?vue&type=script&lang=js"
import style0 from "./ClientListAddNew.vue?vue&type=style&index=0&id=365ea51d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports