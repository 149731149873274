<template>
  <b-modal
    id="add-new-client-sidebar"
    :visible="isAddNewClientSidebarActive"
    bg-variant="white"
    shadow
    backdrop
    no-close-on-backdrop
    :title="showTitleModal"
    right
    :hide-header-close="isLoading"
    size="lg"
    hide-footer
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-client-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="pb-2" @submit.prevent="handleSubmit(onSubmit) " @reset.prevent="resetForm">
          <b-row>
            <b-col :md="!modeShow ? modeEdit ? '12':'6':'12'">
              <b-row>
                <b-col md="12" class="text-center mb-1">
                  <h4>{{$t('information')}}</h4>
                </b-col>
                <b-col md="12">
                  <!-- Client name -->
                  <validation-provider
                    #default="validationContext"
                    name="Name"
                    rules="required"
                  >
                    <b-form-group label="Name" label-for="name">
                      <template #label>{{ $t("labels.name") }}</template>
                      <b-form-input
                        id="name"
                        v-model="clientData.name"
                        :state="getValidationState(validationContext)"
                        trim
                        :class="{'pointer-events-none': modeShow}"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="12">
                  <!-- Description -->
                  <validation-provider
                    #default="validationContext"
                    name="Description"
                    rules="required"
                  >
                    <b-form-group label="Description" label-for="description">
                      <template #label>{{ $t("labels.description") }}</template>
                      <b-form-input
                        id="description"
                        v-model="clientData.description"
                        :state="getValidationState(validationContext)"
                        trim
                        :class="{'pointer-events-none': modeShow}"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <!-- Whitelabels -->
                  <validation-provider
                    v-slot="{ errors }"
                    name="Whitelabels"
                    rules="required"
                  >
                    <b-form-group label="Whitelabels" label-for="Whitelabels">
                      <template #label>{{ $t("labels.whitelabels") }}</template>
                      <v-select
                        v-model="clientData.whitelabels"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        label="name"
                        :reduce="(item) => item._id"
                        :options="whitelabelOptions"
                      />
                      <span class="text-danger font-small-2">{{errors[0]}}</span>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                 <!-- Currencies -->
                <!-- <b-col md="12" v-if="modeEdit">

                  <validation-provider
                    #default="validationContext"
                    name="Currencies"
                    rules="required"
                  >
                    <b-form-group label="Currencies" label-for="currencies">
                      <template #label>{{ $t("labels.currencies") }}</template>
                      <v-select
                        v-model="clientData.currencies"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        :options="currenciesOptions"
                        :class="{'pointer-events-none': modeShow}"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col> -->

                <!-- Products -->
                <!-- <b-col md="12" v-if="modeEdit">

                  <validation-provider
                    #default="validationContext"
                    name="Products"
                    rules="required"
                  >
                    <b-form-group label="Products" label-for="products">
                      <template #label>{{ $t("labels.products") }}</template>
                      <v-select
                        v-model="clientData.products"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        label="name"
                        :options="productsOptions"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col> -->


                 <!-- Bet client check -->
                <!-- <b-col md="12">
                  <b-form-group label="Bet client?" label-cols="10">
                    <b-form-checkbox
                      v-model="clientData.betClient"
                      id="BetClient"
                      :checked="false"
                      switch
                    />
                  </b-form-group>
                </b-col> -->
              </b-row>
            </b-col>

            <b-col :md="modeEdit ? '12':'6'" :v-if="!modeShow">
              <b-row>
                <b-col md="12" class="text-center mb-1" v-if="!modeEdit">
                  <h4>{{$t('access_data')}}</h4>
                </b-col>
                <b-col md="12">
                  <!-- Email -->
                  <validation-provider
                    v-if="!modeEdit"
                    #default="validationContext"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-group label="Email" label-for="email">
                      <template #label>{{ $t("labels.email") }}</template>
                      <b-form-input
                        id="email"
                        v-model="clientData.email"
                        :state="getValidationState(validationContext)"
                        trim
                        :class="{'pointer-events-none': modeShow}"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="12">
                  <!-- Username -->
                  <validation-provider
                    v-if="!modeEdit"
                    #default="validationContext"
                    name="Username"
                    rules="required"
                  >
                    <b-form-group label="Username" label-for="username">
                      <template #label>{{ $t("labels.username") }}</template>
                      <b-form-input
                        id="username"
                        v-model="clientData.userName"
                        :state="getValidationState(validationContext)"
                        trim
                        :class="{'pointer-events-none': modeShow}"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="12">
                  <!-- Password -->
                  <validation-provider
                    v-if="!modeEdit"
                    #default="validationContext"
                    name="Password"
                    rules="required"
                  >
                    <b-form-group label="Password" label-for="password">
                      <template #label>{{ $t("labels.password") }}</template>
                      <b-form-input
                        id="password"
                        v-model="clientData.password"
                        :state="getValidationState(validationContext)"
                        trim
                        :class="{'pointer-events-none': modeShow}"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="12">
                  <!-- Role -->
                  <validation-provider
                    v-if="!modeEdit"
                    v-slot="{ errors }"
                    name="Role"
                    rules="required"
                  >
                    <b-form-group label="Role" label-for="role">
                      <template #label>{{ $t("labels.roles") }}</template>
                      <v-select
                        v-model="clientData.roles"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :reduce="(e) => e._id"
                        :options="rolesOptions"
                        :class="{'pointer-events-none': modeShow}"
                      />
                      <span class="text-danger font-small-2">{{errors[0]}}</span>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="12" >
                  <!-- Id del cliente -->
                  <validation-provider
                      #default="validationContext"
                      name="clientId"
                      rules="required"
                  >
                    <b-form-group label="clientId" label-for="clientId">
                      <template #label>Id del cliente</template>
                      <b-form-input
                          id="clientId"
                          v-model="clientData.clientId"
                          :state="getValidationState(validationContext)"
                          trim
                          :class="{'pointer-events-none': modeShow}"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col :md="modeEdit ? '6':'12'">
                  <!-- Token del cliente -->
                  <validation-provider
                      #default="validationContext"
                      name="tokenClient"
                      rules="required"
                  >
                    <b-form-group label="tokenClient" label-for="tokenClient">
                      <template #label>Token del cliente</template>
                      <b-form-input
                          id="tokenClient"
                          v-model="clientData.tokenClient"
                          :state="getValidationState(validationContext)"
                          trim
                          :class="{'pointer-events-none': modeShow}"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col :md="modeEdit ? '6':'12'">
                  <!-- Url del gateway -->
                  <validation-provider

                      #default="validationContext"
                      name="urlGateway"
                      rules="required"
                  >
                    <b-form-group label="urlGateway" label-for="urlGateway">
                      <template #label>Url del gateway</template>
                      <b-form-input
                          id="urlGateway"
                          v-model="clientData.urlGateway"
                          :state="getValidationState(validationContext)"
                          trim
                          :class="{'pointer-events-none': modeShow}"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col :md="modeEdit ? '6':'12'">
                  <!-- Url de wallet -->
                  <validation-provider

                      #default="validationContext"
                      name="urlWallet"
                      rules="required"
                  >
                    <b-form-group label="urlWallet" label-for="urlWallet">
                      <template #label>Url de la wallet</template>
                      <b-form-input
                          id="urlWallet"
                          v-model="clientData.urlWallet"
                          :state="getValidationState(validationContext)"
                          trim
                          :class="{'pointer-events-none': modeShow}"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col :md="modeEdit ? '6':'12'">
                  <!-- Wallet public key -->
                  <validation-provider
                      #default="validationContext"
                      name="walletPublicKey"
                      rules="required"
                  >
                    <b-form-group label="walletPublicKey" label-for="walletPublicKey">
                      <template #label>Wallet public key</template>
                      <b-form-input
                          id="walletPublicKey"
                          v-model="clientData.walletPublicKey"
                          :state="getValidationState(validationContext)"
                          trim
                          :class="{'pointer-events-none': modeShow}"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col :md="modeEdit ? '6':'12'">
                  <!-- hashKey -->
                  <validation-provider
                      #default="validationContext"
                      name="hashKey"
                      rules="required"
                  >
                    <b-form-group label="hashKey" label-for="hashKey">
                      <template #label>hashKey</template>
                      <b-form-input
                          id="hashKey"
                          v-model="clientData.hashKey"
                          :state="getValidationState(validationContext)"
                          trim
                          :class="{'pointer-events-none': modeShow}"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex mt-2" v-if="!modeShow">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner v-if="isLoading" small></b-spinner>
              <div v-else>{{ modeEdit ? $t("buttons.update") : $t("buttons.add") }}</div>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              :disabled="isLoading"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BFormSelect,
  BRow,
  BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, onUnmounted, computed } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import whitelabelStoreModule from "../../whitelabel/whitelabelStoreModule";
import roleStoreModule from "../../../pages/roles/roleStoreModule";
import { v4 as uuidv4 } from "uuid";
import { validateTokenRefreshData } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    vSelect,
    BFormSelect,
    BRow,
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewClientSidebarActive",
    event: "update:is-add-new-client-sidebar-active",
  },
  props: {
    isAddNewClientSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  computed:{
    showTitleModal(){
      if(this.modeEdit){
        return this.$t('tabs.editclient')
      }
      if(this.modeShow){
        return this.$t('tabs.viewclient')
      }
      return this.$t('tabs.addclient')
    },
  },
  methods:{
    methoEditShow(client, mode){
      const blankdata = {
        _id: client._id,
        name: client.name,
        description: client.description,
        currencies: client.currencies,
        products: client.products,
        whitelabels: client.whitelabels,
        clientId: client.clientId,
        
      }
      this.clientData = blankdata
      if(mode == 'edit'){
        this.modeEdit = true
      }else if(mode == 'show'){
        this.modeShow = true
      }     
    }
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  watch: {
    isAddNewClientSidebarActive(newVal) {
      if (!newVal) {
        this.resetclientData();
        this.modeEdit = false;
      } else {
        this.getRoles();
        this.getWhitelabels();
        
      }
    },
  },
  setup(props, { emit }) {
    const MODULE_APP_STORE_MODULE_NAME = "app-whitelabel";
    const ROLE_APP_STORE_MODULE_NAME = "app-role";
    if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME))
      store.registerModule(MODULE_APP_STORE_MODULE_NAME, whitelabelStoreModule);
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME))
      store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule);
    onUnmounted(() => {
      if (store.hasModule(MODULE_APP_STORE_MODULE_NAME))
        store.unregisterModule(MODULE_APP_STORE_MODULE_NAME);
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME))
        store.unregisterModule(ROLE_APP_STORE_MODULE_NAME);
    });

    const toast = useToast()

    const modeEdit = ref(false);
    const modeShow = ref(false);

    const isLoading = ref(false)

    const rolesOptions = ref([]);
    const getRoles = () => {
      store.dispatch("app-role/fetchRoles").then((response) => {
        rolesOptions.value = response.data.roles;
      });
    };
    const whitelabelOptions = ref([]);
    const getWhitelabels = () => {
      store
        .dispatch("app-whitelabel/fetchWhitelabels")
        .then((response) => {
          console.log(idsWhitelabelsSelect.value, "idsWhitelabelsSelect.value")
          whitelabelOptions.value = response.data.whitelabels.filter((whitelabel) => idsWhitelabelsSelect.value.includes(whitelabel._id) || whitelabel.client == null)
          console.log(whitelabelOptions.value, "whitelabelOptions");
        });
    };

    // store.dispatch('app-whitelabel/fetchProducts')
    //   .then(response => {
    //     products.value = response.data
    //   })

    const currenciesOptions = ref([]);
    const productsOptions = ref([]);
    const selectMethoWhitelabel = (ids) => {
      currenciesOptions.value = [];
      productsOptions.value = [];

      const whitelabelSelect = whitelabelOptions.value.filter((whitelabel) =>
        ids.includes(whitelabel._id)
      );
      whitelabelSelect.forEach((whitelabel) => {
        currenciesOptions.value.push(...whitelabel.currencies);
        productsOptions.value.push(...whitelabel.products);
      });
      // currenciesOptionsCopy uniques values
      currenciesOptions.value = [...new Set(currenciesOptions.value.flat())];
      // productsOptions objetos unicos por el _id
      // filter para quitar los repetidos
      productsOptions.value = productsOptions.value.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t._id === item._id)
      );

      if (whitelabelSelect.length < 1) {
        currenciesOptions.value = [];
        productsOptions.value = [];

        clientData.value.currencies = [];
        clientData.value.products = [];
      }

      clientData.value.currencies.forEach((currency) => {
        if (!currenciesOptions.value.includes(currency)) {
          clientData.value.currencies.splice(
            clientData.value.currencies.indexOf(currency),
            1
          );
        }
      });

      clientData.value.products.forEach((productId) => {
        if (
          !productsOptions.value.some(
            (productOption) => productOption._id === productId
          )
        ) {
          clientData.value.products.splice(
            clientData.value.products.indexOf(productId),
            1
          );
        }
      });
    };

    // store.dispatch('app-whitelabel/fetchCurrencies')
    //   .then(response => {
    //     currenciesOptions.value = response.data
    //   })

    const blankClientData = {
      name: "",
      description: "",
      email: "",
      password: "",
      userName: "",
      // currencies: [],
      // products: [],
      whitelabels: [],
    };
    

    const clientData = ref(JSON.parse(JSON.stringify(blankClientData)));
    const resetclientData = () => {
      clientData.value = JSON.parse(JSON.stringify(blankClientData));
    };

    const onSubmit = async () => {
      try {
        isLoading.value = true
        if (modeEdit.value) {
          const id = clientData.value._id;
          delete clientData.value._id;
          await store.dispatch("app-client/updateClient", {id, client: clientData.value});
        } else {
          await store.dispatch("app-client/addClient", clientData.value);
        }
        clientData.value.roles = [clientData.value.roles];

        emit("refetch-data");
        emit("update:is-add-new-client-sidebar-active", false);
        validateTokenRefreshData();
      } catch (error) {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      } finally {
        isLoading.value = false
      }
    };

    const idsWhitelabelsSelect = ref([]);

    const methoModeEdit = async (client) => {
      modeEdit.value = true;
      const item = JSON.parse(JSON.stringify(client));
      idsWhitelabelsSelect.value = item.whitelabels.map((whitelabel) => whitelabel._id);

      console.log(client)
      clientData.value = {
        _id: item._id,
        name: item.name,
        description: item.description,
        // currencies: item.currencies,
        // products: item.products,
        whitelabels: idsWhitelabelsSelect.value,
        clientId: client.clientId,
        tokenClient: client.tokenClient,
        urlGateway: client.urlGateway,
        urlWallet: client.urlWallet,
        walletPublicKey: client.walletPublicKey,
        hashKey: client.hashKey
      };
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetclientData);

    return {
      clientData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      currenciesOptions,
      selectMethoWhitelabel,
      productsOptions,
      rolesOptions,
      modeEdit,
      modeShow,
      methoModeEdit,
      whitelabelOptions,
      idsWhitelabelsSelect,
      getRoles,
      getWhitelabels,
      resetclientData,
      isLoading
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-client-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
