<template>
  <b-modal
    id="view-client-sidebar"
    :visible="isViewClientSidebarActive"
    bg-variant="white"
    shadow
    backdrop
    no-close-on-backdrop
    :title="showTitleModal"
    right
    size="lg"
    hide-footer
    @hidden="resetForm"
    @change="(val) => $emit('update:is-view-client-sidebar-active', val)"
  >
    <template >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="pb-2" @submit.prevent="handleSubmit(onSubmit) " >
          <b-row>
            <b-col md="12">
              <b-row>
                <b-col md="12" class="text-center mb-1">
                  <h4>{{$t('information')}}</h4>
                </b-col>
                <b-col md="12">
                  <!-- Client name -->
                  <validation-provider
                    #default="validationContext"
                    name="Name"
                    rules="required"
                  >
                    <b-form-group label="Name" label-for="name">
                      <template #label>{{ $t("labels.name") }}</template>
                      <b-form-input
                        id="name"
                        v-model="clientData.name"
                        :state="getValidationState(validationContext)"
                        trim
                        class="pointer-events-none"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="12">
                  <!-- Description -->
                  <validation-provider
                    #default="validationContext"
                    name="Description"
                    rules=""
                  >
                    <b-form-group label="Description" label-for="description">
                      <template #label>{{ $t("labels.description") }}</template>
                      <b-form-input
                        id="description"
                        v-model="clientData.description"
                        :state="getValidationState(validationContext)"
                        trim
                        class="pointer-events-none"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <!-- Whitelabels -->
                  <validation-provider
                    v-if="!modeEdit"
                    #default="validationContext"
                    name="Whitelabels"
                    rules="required"
                  >
                    <b-form-group label="Whitelabels" label-for="Whitelabels">
                      <template #label>{{ $t("labels.whitelabels") }}</template>
                      <v-select
                        v-model="clientData.whitelabels"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        label="name"
                        :reduce="(item) => item._id"
                        :options="whitelabelOptions"
                        @input="selectMethoWhitelabel"
                        class="pointer-events-none"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="12">
                  <!-- Currencies -->
                  <validation-provider
                    #default="validationContext"
                    name="Currencies"
                    rules="required"
                  >
                    <b-form-group label="Currencies" label-for="currencies">
                      <template #label>{{ $t("labels.currencies") }}</template>
                      <v-select
                        v-model="clientData.currencies"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        :options="currenciesOptions"
                        class="pointer-events-none"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="12">
                  <!-- Products -->
                  <validation-provider
                    v-if="!modeEdit"
                    #default="validationContext"
                    name="Products"
                    rules="required"
                  >
                    <b-form-group label="Products" label-for="products">
                      <template #label>{{ $t("labels.products") }}</template>
                      <v-select
                        v-model="clientData.products"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        label="name"
                        :options="productsOptions"
                        class="pointer-events-none"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                 <!-- Bet client check -->
                <!-- <b-col md="12">
                  <b-form-group label="Bet client?" label-cols="10">
                    <b-form-checkbox
                      v-model="clientData.betClient"
                      id="BetClient"
                      :checked="false"
                      switch
                    />
                  </b-form-group>
                </b-col> -->
              </b-row>
            </b-col>

          </b-row>
          <!-- Form Actions -->

        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BFormSelect,
  BRow,
  BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, onUnmounted } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import whitelabelStoreModule from "../../whitelabel/whitelabelStoreModule";
import roleStoreModule from "../../../pages/roles/roleStoreModule";
import { v4 as uuidv4 } from "uuid";
import { validateTokenRefreshData } from "@/auth/utils";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    vSelect,
    BFormSelect,
    BRow,
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isViewClientSidebarActive",
    event: "update:is-view-client-sidebar-active",
  },
  props: {
    isViewClientSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  computed:{
    showTitleModal(){
      if(this.modeEdit){
        return this.$t('tabs.editclient')
      }
      if(this.modeShow){
        return this.$t('tabs.viewclient')
      }
      return this.$t('tabs.addclient')
    },
  },
  methods:{
    methoEditShow(client, mode){
      const blankdata = {
        _id: client._id,
        name: client.name,
        description: client.description,
        currencies: client.currencies,
        products: client.products,
        whitelabels: client.whitelabels,
        
      }
      this.clientData = blankdata
      if(mode == 'edit'){
        this.modeEdit = true
      }else if(mode == 'show'){
        this.modeShow = true
      }     
    }
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  watch: {
    isViewClientSidebarActive(newVal) {
      if (!newVal) {
        this.resetclientData();
        this.modeEdit = false;
      } else {
        this.getRoles();
        this.getWhitelabels();
      }
    },
  },
  setup(props, { emit }) {
    const MODULE_APP_STORE_MODULE_NAME = "app-whitelabel";
    const ROLE_APP_STORE_MODULE_NAME = "app-role";
    if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME))
      store.registerModule(MODULE_APP_STORE_MODULE_NAME, whitelabelStoreModule);
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME))
      store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule);
    onUnmounted(() => {
      if (store.hasModule(MODULE_APP_STORE_MODULE_NAME))
        store.unregisterModule(MODULE_APP_STORE_MODULE_NAME);
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME))
        store.unregisterModule(ROLE_APP_STORE_MODULE_NAME);
    });

    const modeEdit = ref(false);
    const modeShow = ref(false);

    const rolesOptions = ref([]);
    const getRoles = () => {
      store.dispatch("app-role/fetchRoles").then((response) => {
        rolesOptions.value = response.data.roles;
      });
    };
    const whitelabelOptions = ref([]);
    const getWhitelabels = () => {
      store
        .dispatch("app-whitelabel/fetchWhitelabels", { newClient: true })
        .then((response) => {
          whitelabelOptions.value = response.data.whitelabels;
        });
    };

    // store.dispatch('app-whitelabel/fetchProducts')
    //   .then(response => {
    //     products.value = response.data
    //   })

    const currenciesOptions = ref([]);
    const productsOptions = ref([]);
    const selectMethoWhitelabel = (ids) => {
      currenciesOptions.value = [];
      productsOptions.value = [];

      const whitelabelSelect = whitelabelOptions.value.filter((whitelabel) =>
        ids.includes(whitelabel._id)
      );
      whitelabelSelect.forEach((whitelabel) => {
        currenciesOptions.value.push(...whitelabel.currencies);
        productsOptions.value.push(...whitelabel.products);
      });
      // currenciesOptionsCopy uniques values
      currenciesOptions.value = [...new Set(currenciesOptions.value.flat())];
      // productsOptions objetos unicos por el _id
      // filter para quitar los repetidos
      productsOptions.value = productsOptions.value.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t._id === item._id)
      );

      if (whitelabelSelect.length < 1) {
        currenciesOptions.value = [];
        productsOptions.value = [];

        clientData.value.currencies = [];
        clientData.value.products = [];
      }

      clientData.value.currencies.forEach((currency) => {
        if (!currenciesOptions.value.includes(currency)) {
          clientData.value.currencies.splice(
            clientData.value.currencies.indexOf(currency),
            1
          );
        }
      });

      clientData.value.products.forEach((productId) => {
        if (
          !productsOptions.value.some(
            (productOption) => productOption._id === productId
          )
        ) {
          clientData.value.products.splice(
            clientData.value.products.indexOf(productId),
            1
          );
        }
      });
    };

    // store.dispatch('app-whitelabel/fetchCurrencies')
    //   .then(response => {
    //     currenciesOptions.value = response.data
    //   })

    const blankClientData = {
      name: "",
      description: "",
      email: "",
      password: "",
      userName: "",
      currencies: [],
      products: [],
      whitelabels: [],
    };
    

    const clientData = ref(JSON.parse(JSON.stringify(blankClientData)));
    const resetclientData = () => {
      clientData.value = JSON.parse(JSON.stringify(blankClientData));
    };

    const onSubmit = async () => {
      try {
        if (modeEdit.value) {
          await store.dispatch("app-client/updateClient", clientData.value);
        } else {
          await store.dispatch("app-client/addClient", clientData.value);
        }
        clientData.value.roles = [clientData.value.roles];

        emit("refetch-data");
        emit("update:is-view-client-sidebar-active", false);
        validateTokenRefreshData();
      } catch (error) {
        console.log(error);
      } finally {
      }
    };

    const methoModeEdit = async (client) => {
      modeEdit.value = true;
      const item = JSON.parse(JSON.stringify(client));

      clientData.value = {
        _id: item._id,
        name: item.name,
        description: item.description,
        currencies: item.currencies,
      };
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetclientData);

    return {
      clientData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      currenciesOptions,
      selectMethoWhitelabel,
      productsOptions,
      rolesOptions,
      modeEdit,
      modeShow,
      methoModeEdit,
      whitelabelOptions,
      getRoles,
      getWhitelabels,
      resetclientData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-client-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
