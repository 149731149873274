<template>
  <div>
    <client-list-add-new
      :is-add-new-client-sidebar-active.sync="isAddNewClientSidebarActive"
      @refetch-data="refetchData"
      ref="clientListAddNew"
    />
    <client-view
      :is-view-client-sidebar-active.sync="isViewClientSidebarActive"
      @refetch-data="refetchData"
      ref="clientView"
    />

    <!-- Filters -->
    <!-- < -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <!--        <b-row class="pb-2">-->
        <!--          <b-col cols="12" md="4">-->
        <!--            <b-button variant="primary" class="d-flex align-items-center justify-content-center"-->
        <!--                      @click="downloadFile()">-->
        <!--              <feather-icon icon="UserPlusIcon" size="16" class="mr-50"/>-->
        <!--              <span class="text-nowrap">{{ $t('Exportar') }}</span>-->
        <!--            </b-button>-->
        <!--          </b-col>-->
        <!--        </b-row>-->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("labels.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("labels.entries") }}</label>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("labels.status") }}</label>
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              label="label"
              :reduce="status => status.value"
              aria-placeholder="status"
              :clearable="false"
              class="d-inline-block w-50 mx-50"
            />
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="4">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search') + '...'"
              />
              <div>
                <b-button
                  variant="primary"
                  @click="isAddNewClientSidebarActive = true"
                >
                  <span class="text-nowrap">{{ $t("buttons.add") }}</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay
        :show="loading"
        :variant="$store.state.appConfig.layout.skin"
        blur="2"
        class="p-50"
        opacity="0.50"
      >
        <b-table
          ref="refClientListTable"
          class="position-relative"
          :items="fetchClients"
          responsive
          :fields="tableColumns"
          primary-key="_id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="messages Not"
          :sort-desc.sync="isSortDirDesc"
          style="min-height: 300px"
        >
          <!-- Column: Status -->
          <!-- <template #cell(status)="data">
            <b-badge pill :variant="`light-${resolveClientStatusVariant(data.item.status)}`" class="text-capitalize">
              {{ data.item.status }}
            </b-badge>
          </template> -->
          <template #cell(status)="data">
            <b-form-checkbox
              name="check-button"
              @change="updateStatus(data.item)"
              v-model="data.item.status"
              switch
              inline
            >
            </b-form-checkbox>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              no-flip
              menu-class="list"
              class="list"
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <div>
                <b-dropdown-item @click="editShowItem(data.item, 'show')">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">{{
                      $t("buttons.details")
                  }}</span>
                </b-dropdown-item>

                <b-dropdown-item @click="EditMode(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span
                    class="align-middle ml-50"
                    >{{ $t("buttons.edit") }}</span
                  >
                </b-dropdown-item>

                <!--                <b-dropdown-item @click="removeClient(data.item._id)">-->
                <!--                  <feather-icon icon="TrashIcon"/>-->
                <!--                  <span class="align-middle ml-50">{{-->
                <!--                      $t("buttons.delete")-->
                <!--                    }}</span>-->
                <!--                </b-dropdown-item>-->

                <b-dropdown-item @click="updateProducts(data.item._id)">
                  <!-- TODO opdate products -->
                  <feather-icon icon="RefreshCcwIcon" />
                  <span class="align-middle ml-50"
                    >{{
                      $t('buttons.updateProducts')
                    }}
                  </span>
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </template>
        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >{{ $t("labels.show") }} {{ dataMeta.from }}
              {{ $t("labels.to") }} {{ dataMeta.to }} {{ $t("labels.of") }}
              {{ dataMeta.of }} {{ $t("labels.entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalClients"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import {ref, onUnmounted} from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ClientsListFilters from "./ClientsListFilters.vue";
import useClientsList from "./useClientsList";
import clientStoreModule from "../clientStoreModule";
import ClientListAddNew from "./ClientListAddNew.vue";
import ClientView from "./ClientView.vue";

export default {
  components: {
    ClientsListFilters,
    ClientListAddNew,
    ClientView,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        {key: "name", sortable: true, label: this.$t("labels.name")},
        {
          key: "description",
          sortable: true,
          label: this.$t("labels.description"),
        },
        {key: "status", sortable: true, label: this.$t("labels.status")},
        {key: "actions", label: this.$t("labels.actions")},
      ],
    };
  },
  setup() {
    const CLIENT_APP_STORE_MODULE_NAME = "app-client";
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
      store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });

    const isAddNewClientSidebarActive = ref(false);
    const isViewClientSidebarActive = ref(false);

    const statusOptions = [
      {label: "Active", value: true},
      {label: "Inactive", value: false},
    ];
    const {
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientListTable,
      refetchData,
      recordDeleted,
      // UI

      resolveClientStatusVariant,

      // Extra Filters

      statusFilter,
    } = useClientsList();
    return {
      // Sidebar
      isAddNewClientSidebarActive,
      isViewClientSidebarActive,

      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientListTable,
      refetchData,
      recordDeleted,
      // UI

      resolveClientStatusVariant,
      statusOptions,

      // Extra Filters

      statusFilter,
    };
  },
  methods: {
    downloadFile() {
      const VUE_APP_URL = process.env.VUE_APP_URL;
      const downloadFile = VUE_APP_URL + "/media/exports/excel/clients.xlsx"
      window.open(downloadFile, "_self")
    },
    removeClient(Id) {
      store.dispatch("app-client/removeClient", {id: Id}).then(() => {
        this.recordDeleted = !this.recordDeleted;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('client.clientRemoved'),
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },
    EditMode(client) {
      this.$refs.clientListAddNew.methoModeEdit(client);
      this.isAddNewClientSidebarActive = true;
    },
    editShowItem(client, mode) {
      this.$refs.clientView.methoEditShow(client, mode);
      this.isViewClientSidebarActive = true;
    },
    updateStatus(client) {
      store.dispatch("app-client/updatStatusClient", client).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('client.clientUpdated'),
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },

    updateProducts(clientID) {
      this.loading = true
      store.dispatch("app-client/updateAvailableProducts", clientID).then(() => {
        this.notify('messages.updateProductsSuccess', 'success', 'RefreshCcwIcon')
        this.loading = false

      }).catch(() => {
        this.notify('messages.updateProductsError', 'error', 'RefreshCcwIcon');
        this.loading = false
      })
    },

    notify(title, variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t(title),
          icon: icon,
          variant: variant,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>
<!-- 
<style lang="scss">

</style>
 -->
